import { IMemePayload } from 'types/IMeme';
import { getRequest, postRequest } from './httpMethods';

export const getMemes = () => {
  return getRequest('memes/');
};

export const createNewMeme = (payload: IMemePayload) => {
  return postRequest('memes/', undefined, payload);
};

export const getSingleMeme = (id: string) => {
  return getRequest(`memes/${id}`);
};
