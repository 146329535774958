import React from 'react';
import { ThemeProvider } from 'context/ThemeContext';

type Props = {
  children: React.ReactNode;
};

const AppProviders = ({ children }: Props) => {
  return <ThemeProvider>{children}</ThemeProvider>;
};

export default AppProviders;
