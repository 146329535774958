import React, { PropsWithChildren } from 'react';
import Colors from 'constants/theme/Colors';

const DEFAULT_COLORS = {
  darkBlue5: Colors.DARK_BLUE_5,
  darkBlue10: Colors.DARK_BLUE_10,
  green: Colors.GREEN,
  green10: Colors.GREEN_10,
  gray: Colors.GRAY,
  gray2: Colors.GRAY_2,
  gray10: Colors.GRAY_10,
  lightBlack: Colors.LIGHT_BLACK,
  black: Colors.BLACK,
  white: Colors.WHITE
};

const themes = {
  dark: {
    colors: {
      ...DEFAULT_COLORS
    }
  },
  light: {
    colors: {
      ...DEFAULT_COLORS
    }
  }
};

const initialState = {
  dark: false,
  theme: themes.light,
  toggleTheme: () => {}
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }: PropsWithChildren) {
  const [dark, setDark] = React.useState(false); // Default theme is light

  // On mount, read the preferred theme from the persistence
  React.useEffect(() => {
    const isDark = localStorage.getItem('dark') === 'true';
    setDark(isDark);
  }, [dark]);

  const toggleTheme = () => {
    const isDark = !dark;
    localStorage.setItem('dark', JSON.stringify(isDark));
    setDark(isDark);
  };

  const theme = dark ? themes.dark : themes.light;

  return (
    <ThemeContext.Provider value={{ theme, dark, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
