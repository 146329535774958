// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import './PreviousMemes.css';
import RightArrow from 'assets/icons/right-arrow.svg';

const PreviousMemes = () => {
  return (
    <div className="previous-memes-section">
      <h3 className="home-subtitle-2 mt-5 mb-5">
        Check out some of the awesome memes submitted by your fellow community
        members!
      </h3>

      <div className="submissions">
        <Swiper
          direction="horizontal"
          modules={[Navigation, EffectFade]}
          loop={true}
          slidesPerView={2}
          centeredSlides={true}
          loopedSlides={5}
          initialSlide={0}
          spaceBetween={70}
          navigation={{
            nextEl: '.swiper .swiper-button-next',
            prevEl: '.swiper .swiper-button-prev',
            hiddenClass: 'hidden',
            disabledClass: 'hidden'
          }}
          breakpoints={{
            769: {
              slidesPerView: 'auto',
              spaceBetween: 0
            }
          }}
        >
          <>
            {Array(10)
              .fill(1)
              .map((_, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className="single-submission-wrap">
                      <div className="single-submission">
                        <div
                          className="single-submission-image"
                          style={{
                            backgroundImage: `url('${require('assets/images/memes/meme' +
                              i +
                              '.jpeg')}'`
                          }}
                        ></div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </>
          <div className="swiper-arrows">
            <div className="container full-height w-container">
              <div className="div-block-19">
                <div
                  className="swiper-button-prev carousel-arrow"
                  role="button"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-70e97e528dbfd971"
                >
                  <img
                    src={RightArrow}
                    loading="lazy"
                    alt="arrow"
                    className="arrow arrow-rotate"
                  ></img>
                </div>
                <div
                  className="swiper-button-next carousel-arrow"
                  role="button"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-70e97e528dbfd971"
                >
                  <img
                    src={RightArrow}
                    loading="lazy"
                    alt="arrow"
                    className="arrow"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </Swiper>
      </div>
      <div className="text-center">
        <a href="https://memes.mybirdbuddy.com/" className="button-3 w-button">
          See what we meme!
        </a>
      </div>
    </div>
  );
};

export default PreviousMemes;
