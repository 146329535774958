/* eslint-disable camelcase */
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { logger } from 'services/logging/loggingService';
import API from 'services/api';
import { IImage } from 'types/IImage';
import { memeTemplates } from 'constants/memeTemplates';
import { APP_TITLE } from 'constants/constants';
import HeadlineTitle from 'components/HeadlineTitle';
import PageContainer from 'components/PageContainer';
import ImageUpload from 'components/ImageUpload';
import TemplatesCarousel from 'components/TemplatesCarousel';
import TextInput from 'components/TextInput';
import Button, { ButtonCustomClasses } from 'components/Button';
import SliderComponent from 'components/SliderComponent';
import DownloadAndShare from 'components/DownloadAndShare';
import ConfirmMemeModal from 'components/ConfirmMemeModal';
import SwitchIcon from 'assets/icons/switch_icon.svg';
import './homepage.scss';
import ImageCarouselRow from 'components/ImageCarouselRow';
import { ICategory } from 'types/ICategory';
import { isMobile } from 'react-device-detect';
import PreviousMemes from 'components/PreviousMemes';

//TODO: this component needs to be splited on different pages, meme page, edit meme page

export default function HomePage() {
  const [activeTemplate, setActiveTemplate] = useState<number>(0);
  const [imageUrlFromList, setImageUrlFromList] = useState<IImage | null>(null);
  const [categories, setCategories] = useState<ICategory[] | []>([]);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [topText, setTopText] = useState<string>('');
  const [bottomText, setBottomText] = useState<string>('');
  const [textSize, setTextSize] = useState<number>(30);
  const [scaleFactor, setScaleFactor] = useState<number>(1);
  const [renderedPreviewImageWidth, setRenderedPreviewImageWidth] = useState<
    number | null
  >(null);
  const [renderedConfirmImageWidth, setRenderedConfirmImageWidth] = useState<
    number | null
  >(null);
  const [maxTextSize, setMaxTextSize] = useState(50);
  const [minTextSize, setMinTextSize] = useState(10);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>('');
  const [memeUrl, setMemeUrl] = useState<string>('');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await API.getGalleryImages();
        setCategories(response.data.results);
      } catch (ex) {
        logger.error('Fetch images', ex);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (!selectedImage) {
      setPreviewUrl(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewUrl(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  const toggleModalState = useCallback(() => {
    setModalIsOpen(!modalIsOpen);
  }, [modalIsOpen]);

  const handleImageUpload = useCallback((acceptedFiles: File[]) => {
    setSelectedImage(acceptedFiles[0]);
  }, []);

  const activeTemplateHandler = useCallback((slideIndex: number) => {
    setActiveTemplate(slideIndex);
  }, []);

  const onTopTextValueChange = (event: ChangeEvent) => {
    const target = event.target as HTMLButtonElement;
    setTopText(target?.value.toUpperCase());
  };

  const onBottomTextValue = (event: ChangeEvent) => {
    const target = event.target as HTMLButtonElement;
    setBottomText(target?.value.toUpperCase());
  };

  const onImageLoad = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
    setRenderedPreviewImageWidth(ev.currentTarget.clientWidth);
    const calculatedScaleFactor =
      ev.currentTarget.naturalWidth / ev.currentTarget.clientWidth;
    setScaleFactor(calculatedScaleFactor);
    setTextSize(Math.floor(textSize * scaleFactor));
    setMinTextSize(Math.floor(minTextSize * scaleFactor));
    setMaxTextSize(Math.floor(maxTextSize * scaleFactor));
  };

  const onConfirmImageLoad = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
    setRenderedConfirmImageWidth(ev.currentTarget.clientWidth);
  };

  const getConfirmImageFontSize = () => {
    if (!renderedConfirmImageWidth || !renderedPreviewImageWidth) {
      return textSize;
    }
    return Math.floor(
      (renderedConfirmImageWidth / renderedPreviewImageWidth) * textSize
    );
  };

  const resetState = useCallback(() => {
    setActiveTemplate(0);
    setTopText('');
    setBottomText('');
    setTextSize(30);
  }, []);

  const onSliderValueChange = useCallback((value: number | number[]) => {
    setTextSize(typeof value === 'number' ? value : value[0]);
  }, []);

  const onSwitchImageClick = useCallback(() => {
    return window.location.reload();
  }, []);

  const onGenerateMemeClick = useCallback(() => {
    if (memeTemplates[activeTemplate].top && !topText) {
      return;
    }
    if (memeTemplates[activeTemplate].bottom && !bottomText) {
      return;
    }
    toggleModalState();
  }, [activeTemplate, bottomText, toggleModalState, topText]);

  const onImageSelect = useCallback((image: IImage) => {
    setImageUrlFromList(image);
  }, []);

  const preloadMemeImage = (memeImage: string) => {
    const img = new Image();
    img.src = memeImage;
    img.onload = function () {
      //TODO: Logic needs to change after this component is splitted
      setMemeUrl(memeImage);
      window.scrollTo(0, 0);
      setLoading(false);
      setModalIsOpen(false);
      setTextSize(30);
      setScaleFactor(1);
    };
  };

  const generateMemeFromUploadedImage = useCallback(
    async (imageId: string) => {
      try {
        const response = await API.createNewMeme({
          image_guid: imageId,
          bottom_text: memeTemplates[activeTemplate].bottom ? bottomText : '',
          top_text: memeTemplates[activeTemplate].top ? topText : '',
          font_size: Math.floor(textSize * scaleFactor)
        });
        logger.log('generate meme response', response);

        preloadMemeImage(response.data.meme_image);
      } catch (ex) {
        logger.log('FAILED TO GENERATE MEME', ex);
        toast.error('Failed to generate the meme!');
        throw new Error('Failed to generate meme');
      }
    },
    [activeTemplate, bottomText, textSize, topText, scaleFactor]
  );

  const uploadImage = useCallback(async () => {
    try {
      const response = await API.createNewImage(selectedImage);
      logger.log('upload image', response);
      if (response.status !== 201) {
        throw new Error('failed to upload image');
      }
      return response.data.guid;
    } catch (ex) {
      logger.log('FAILED TO Upload MEME', ex);
      toast.error('Failed to generate the meme!');
      throw new Error('Failed to generate meme');
    }
  }, [selectedImage]);

  const onDownloadImageClick = useCallback(() => {
    if (isMobile) {
      window.open(memeUrl);
    } else {
      saveAs(memeUrl, `meme.jpg`);
    }
  }, [memeUrl]);

  const confirmImage = useCallback(async () => {
    setLoading(true);
    try {
      if (previewUrl) {
        const imageId = await uploadImage();
        logger.log('uploaded image id', imageId);
        generateMemeFromUploadedImage(imageId || '');
        setLoading(false);
        return;
      } else {
        generateMemeFromUploadedImage(imageUrlFromList?.guid || '');
      }
    } catch (ex) {
      logger.log('failed to create meme');
      setLoading(false);
    }
  }, [
    generateMemeFromUploadedImage,
    imageUrlFromList?.guid,
    previewUrl,
    uploadImage
  ]);

  const handleBackButtonClick = () => {
    //TODO: this needs to be pure navigation after home component is splitted on different pages
    if (memeUrl) {
      setMemeUrl('');
    } else if (imageUrlFromList) {
      return window.location.reload();
    }
  };

  //TODO: needs to be removed when home component is splitted on different pages
  const showBackButton = !!memeUrl || !!imageUrlFromList;

  return (
    <PageContainer
      title="Home"
      onBackButtonClick={handleBackButtonClick}
      showBackButton={showBackButton}
    >
      {!memeUrl ? (
        <>
          <HeadlineTitle
            title={APP_TITLE}
            subtitle={
              !imageUrlFromList
                ? 'Are you a lean, mean meme machine? Show us what you’ve got and break the Internet!'
                : ''
            }
          />
        </>
      ) : (
        <HeadlineTitle
          title={'Aaand it’s ready!'}
          subtitle={'It’s probably too good not to share with the world.'}
        />
      )}
      {!imageUrlFromList && (
        <>
          <h3 className="home-subtitle-2">
            For starters choose an image <br />
            from our awesome library!
          </h3>
          <h3 className="home-subtitle-2">Use #memebuddy</h3>
        </>
      )}

      {!!(memeUrl || imageUrlFromList?.image) && (
        <div className="col-lg-6 col-sm-12 d-flex images-container">
          <div className="image-upload-section">
            <ImageUpload
              selectedImage={memeUrl || imageUrlFromList?.image}
              imageUploaded={!!memeUrl}
              textSize={textSize}
              topText={memeTemplates[activeTemplate].top ? topText : ''}
              bottomText={
                memeTemplates[activeTemplate].bottom ? bottomText : ''
              }
              onChange={handleImageUpload}
              onLoad={(ev) => !memeUrl && onImageLoad(ev)}
            />

            {(selectedImage || imageUrlFromList) && !memeUrl && (
              <div className="switch-button" onClick={onSwitchImageClick}>
                <img src={SwitchIcon} />
                <span>Switch image</span>
              </div>
            )}
          </div>
        </div>
      )}
      {!selectedImage && !imageUrlFromList ? (
        <div className="col-lg-12  col-sm-12">
          <>
            {categories.map((category) => {
              return (
                <div className="birds-section" key={category.guid}>
                  <p>{category.name}</p>
                  <ImageCarouselRow
                    onImageSelect={onImageSelect}
                    category={category}
                  />
                </div>
              );
            })}
          </>
        </div>
      ) : (
        <div className="col-lg-6  col-sm-12">
          {!!memeUrl ? (
            <DownloadAndShare
              downloadImage={onDownloadImageClick}
              memeUrl={memeUrl}
            />
          ) : (
            <div className="meme-configurator">
              <p>Choose template</p>
              <ConfirmMemeModal
                loading={loading}
                textSize={getConfirmImageFontSize()}
                topText={memeTemplates[activeTemplate].top ? topText : ''}
                bottomText={
                  memeTemplates[activeTemplate].bottom ? bottomText : ''
                }
                isOpen={modalIsOpen}
                imageUrl={imageUrlFromList?.image || previewUrl || ''}
                confirmImage={confirmImage}
                closeModal={toggleModalState}
                onImageLoad={onConfirmImageLoad}
              />
              <TemplatesCarousel
                imageUrl={imageUrlFromList?.image || previewUrl}
                setActiveTemplate={activeTemplateHandler}
                activeTemplate={activeTemplate}
              />
              {memeTemplates[activeTemplate].top && (
                <div className="mt-5 mb-5">
                  <TextInput
                    inputName="top-text"
                    value={topText}
                    label="Top text"
                    placeholder="Write a top text"
                    onChange={onTopTextValueChange}
                  />
                </div>
              )}
              {memeTemplates[activeTemplate].bottom && (
                <div className="mt-5 mb-5">
                  <TextInput
                    inputName="bottom-text"
                    value={bottomText}
                    label="Bottom text"
                    placeholder="Write a bottom text"
                    onChange={onBottomTextValue}
                  />
                </div>
              )}

              <div className="mb-5">
                <p className="pb-3">Text size</p>
                <SliderComponent
                  value={textSize}
                  onChange={onSliderValueChange}
                  min={minTextSize}
                  max={maxTextSize}
                />
              </div>
              <div className="w-100 buttons">
                <Button
                  customClass={`me-3 ${ButtonCustomClasses.GREEN}`}
                  title="Generate meme"
                  onClick={onGenerateMemeClick}
                />
                <Button
                  title="Reset"
                  customClass={ButtonCustomClasses.BORDERED}
                  onClick={resetState}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {!memeUrl && !imageUrlFromList && <PreviousMemes />}
    </PageContainer>
  );
}
