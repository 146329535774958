import { ReactNode } from 'react';
import HelmetComponent from 'components/HelmetComponent';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';

type Props = {
  title: string;
  description?: string;
  children: ReactNode;
  onBackButtonClick: () => void;
  showBackButton: boolean;
};

const PageContainer = ({
  children,
  title,
  description,
  onBackButtonClick,
  showBackButton
}: Props) => {
  return (
    <>
      <div className="container page-container">
        <HelmetComponent title={title} description={description} />
        <NavBar
          onBackButtonClick={onBackButtonClick}
          showBackButton={showBackButton}
        />
        <div className="row page-container__content">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default PageContainer;
