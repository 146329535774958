type Props = {
  title: string;
  subtitle?: string;
};

const HeadlineTitle = ({ title, subtitle }: Props) => {
  return (
    <div className="col-12 headline-title">
      <h1>{title}</h1>
      {!!subtitle && <h2>{subtitle}</h2>}
    </div>
  );
};

export default HeadlineTitle;
