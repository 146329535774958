import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

type Props = {
  value: number;
  onChange: (value: number | number[]) => void;
  min?: number;
  max?: number;
};

const SliderComponent = ({ value, onChange, min = 20, max = 40 }: Props) => {
  return (
    <>
      <Slider
        range
        className="custom-slider pb-5"
        min={min}
        max={max}
        onChange={onChange}
        defaultValue={value}
        value={value}
      />
    </>
  );
};

export default SliderComponent;
