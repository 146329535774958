import ReactModal from 'react-modal';
import CloseIcon from 'assets/icons/x-icon.svg';
import Button, { ButtonCustomClasses } from './Button';
import { SyntheticEvent } from 'react';

type Props = {
  loading: boolean;
  isOpen: boolean;
  imageUrl: string;
  topText: string;
  bottomText: string;
  textSize: number;
  confirmImage: () => void;
  closeModal: () => void;
  onImageLoad: (ev: SyntheticEvent<HTMLImageElement, Event>) => void;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px'
  }
};

const ConfirmMemeModal = ({
  loading,
  isOpen,
  imageUrl,
  topText,
  bottomText,
  textSize,
  confirmImage,
  closeModal,
  onImageLoad
}: Props) => {
  return (
    <ReactModal
      isOpen={
        isOpen
        /* Boolean describing if the modal should be shown or not. */
      }
      style={customStyles}
      contentLabel={
        'Continue editing?'
        /* String indicating how the content container should be announced
     to screenreaders */
      }
    >
      <div className="modal-container">
        <div className="close-btn" onClick={closeModal}>
          <img src={CloseIcon} />
        </div>
        <div className="image-preview">
          {!!topText && (
            <span className="top-text" style={{ fontSize: `${textSize}px` }}>
              {topText}
            </span>
          )}
          <img src={imageUrl} onLoad={onImageLoad} />
          {!!bottomText && (
            <span className="bottom-text" style={{ fontSize: `${textSize}px` }}>
              {bottomText}
            </span>
          )}
        </div>
        <div className="w-100 buttons buttons--symmetric">
          <Button
            loading={loading}
            customClass={`me-3 ${ButtonCustomClasses.GREEN}`}
            title="Looks cool"
            onClick={confirmImage}
          />
          <Button
            title="Pick another"
            customClass={ButtonCustomClasses.BORDERED}
            onClick={() => window.location.reload()}
          />
        </div>
      </div>
    </ReactModal>
  );
};

export default ConfirmMemeModal;
