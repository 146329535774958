export enum ButtonCustomClasses {
  GREEN = 'general-button--green',
  BORDERED = 'general-button--bordered',
  BLUE = 'general-button--blue'
}

type Props = {
  title: string;
  customClass?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
};

const Button = ({ onClick, loading, customClass, disabled, title }: Props) => {
  const buttonClasses = customClass
    ? `general-button ${customClass}`
    : 'general-button';
  return (
    <button
      className={buttonClasses}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? (
        <div className="spinner-border text-success" role="status">
          <span className="sr-only"></span>
        </div>
      ) : (
        <span>{title}</span>
      )}
    </button>
  );
};

export default Button;
