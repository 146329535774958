import { Helmet } from 'react-helmet';
import BirdBuddyMemePhoto from 'assets/images/bb_meme_photo.png';

type Props = {
  title: string;
  description?: string;
};

const HelmetComponent = ({ title, description }: Props) => {
  return (
    <Helmet defaultTitle="Bird Buddy - Meme Generator">
      <meta charSet="utf-8" />
      <title>{`${title} | Bird Buddy - Meme Generator`}</title>
      <meta name="description" content="Nested component">
        {description}
      </meta>
      <meta property="og:image" content={BirdBuddyMemePhoto} />
    </Helmet>
  );
};

export default HelmetComponent;
