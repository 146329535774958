/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import { IImage } from 'types/IImage';
import RightArrow from 'assets/icons/right-arrow.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ICategory } from 'types/ICategory';
import API from 'services/api';

type Props = {
  category: ICategory;
  onImageSelect: (image: IImage) => void;
};

function NextArrow(props: any) {
  const { onClick, setHasReachedEnd, loading, noMoreImages } = props;
  const onNextButtonClick = () => {
    if (onClick) {
      return onClick();
    }
    setHasReachedEnd(true);
  };
  if (!onClick && noMoreImages) {
    return <></>;
  }
  return (
    <span
      className={`carousel-arrow carousel-arrow--next ${
        loading && 'carousel-arrow--loading'
      }`}
      onClick={onNextButtonClick}
    >
      <img src={RightArrow} />
    </span>
  );
}

function PrevArrow(props: any) {
  const { onClick } = props;
  if (!onClick) {
    return <></>;
  }
  return (
    <span className={'carousel-arrow carousel-arrow--prev'} onClick={onClick}>
      <img src={RightArrow} />
    </span>
  );
}

const ImageCarouselRow = ({ category, onImageSelect }: Props) => {
  const [imagesList, setImagesList] = useState<IImage[] | []>([]);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [noMoreImages, setNoMoreImages] = useState(false);
  const [loading, setLoading] = useState(false);
  const sliderRef = useRef<any>(null);

  const loadMoreImages = useCallback(async () => {
    if (noMoreImages) return;
    try {
      setLoading(true);
      const response = await API.getImages(
        imagesList.length,
        10,
        category.guid
      );
      if (response.status == 200) {
        if (response.data.results.length != 0) {
          sliderRef.current?.slickNext?.();
          setImagesList([...imagesList, ...response.data.results]);
        }

        if (!response.data.next) {
          setNoMoreImages(true);
        }
      }
      setLoading(false);
    } catch (ex) {
      throw new Error('Failed to generate meme');
    }
  }, [imagesList, noMoreImages, category.guid]);

  useEffect(() => {
    if (reachedEnd) {
      loadMoreImages();
      setReachedEnd(false);
    }
  }, [reachedEnd, loadMoreImages]);

  useEffect(() => {
    setImagesList(category.images);
  }, [category]);

  const setHasReachedEnd = (hasReachedEnd: boolean) => {
    if (hasReachedEnd) setReachedEnd(true);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    swipeToSlide: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 1,
    useTransform: true,
    transformEnabled: false,
    left: 0,
    // focusOnSelect: true,

    // variableWidth: true,

    onEdge: (position: 'left' | 'right') => {
      if (position == 'left') {
        setHasReachedEnd(true);
      }
    },
    nextArrow: (
      <NextArrow
        setHasReachedEnd={setHasReachedEnd}
        loading={loading}
        noMoreImages={noMoreImages}
      />
    ),
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  };

  return (
    <Slider {...settings} ref={sliderRef}>
      {imagesList.map((image, index) => {
        return (
          <div key={index} className="carousel-item" data-index={index}>
            <img src={image.image} onClick={() => onImageSelect(image)} />
          </div>
        );
      })}
    </Slider>
  );
};

export default ImageCarouselRow;
