import { ChangeEvent } from 'react';

type Props = {
  inputName: string;
  value: string;
  label: string;
  placeholder: string;
  onChange: (e: ChangeEvent) => void;
};

const TextInput = ({
  inputName,
  value,
  label,
  placeholder,
  onChange
}: Props) => {
  return (
    <div className="general-input">
      <label htmlFor={inputName}>{label}</label>
      <input
        type="text"
        id={inputName}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default TextInput;
