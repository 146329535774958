import { memeTemplates } from 'constants/memeTemplates';
// import Slider from 'react-slick';
//
type Props = {
  imageUrl?: string;
  activeTemplate: null | number;
  setActiveTemplate: (index: number) => void;
};

const TemplatesCarousel = ({
  imageUrl,
  activeTemplate,
  setActiveTemplate
}: Props) => {
  // const settings = {
  //   className: 'slider variable-width',
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 4
  //       }
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1
  //       }
  //     }
  //   ]
  // };
  return (
    <div className="templates-carousel">
      {/* <Slider {...settings}> */}
      {memeTemplates.map((item, index: number) => {
        return (
          <div
            className={
              index === activeTemplate
                ? 'templates-carousel__item templates-carousel__item--active'
                : 'templates-carousel__item'
            }
            key={index}
            onClick={() => setActiveTemplate(index)}
          >
            {item.top && <span className="top-line" />}
            <img src={imageUrl} />
            {item.bottom && <span className="bottom-line" />}
          </div>
        );
      })}
      {/* </Slider> */}
    </div>
  );
};

export default TemplatesCarousel;
