/* eslint-disable no-console */
/** Log levels */
export type LogLevel = 'log' | 'warn' | 'error';

const LOG_LEVEL: LogLevel =
  process.env.NODE_ENV === 'production' ? 'warn' : 'log';

export interface LogFn {
  (message?: unknown, ...optionalParams: unknown[]): void;
}

export interface Logger {
  log: LogFn;
  warn: LogFn;
  error: LogFn;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NO_OP: LogFn = (_message?: unknown, ..._optionalParams: unknown[]) => {};

export class ConsoleLogger implements Logger {
  readonly log: LogFn;

  readonly warn: LogFn;

  readonly error: LogFn;

  constructor(options?: { level?: LogLevel }) {
    const { level } = options || {};

    this.error = console.error.bind(console);

    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;

      return;
    }

    this.warn = console.warn.bind(console);

    if (level === 'warn') {
      this.log = NO_OP;

      return;
    }

    this.log = console.log.bind(console);
  }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL });
