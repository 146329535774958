import { getRequest, postRequest } from './httpMethods';

export const getImages = (offset: number, limit: number, category: string) => {
  return getRequest(
    `images/?offset=${offset}&limit=${limit}&category=${category}`
  );
};

export const getGalleryImages = (page = 1) => {
  return getRequest(`gallery/?page=${page}`);
};

export const createNewImage = (file: File | null) => {
  const form = new FormData();
  form.append('image', file as Blob);
  const headers = {
    accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  };
  return postRequest(
    'images/',
    headers,
    // eslint-disable-next-line camelcase
    form
  );
};

export const getSingleImage = (id: string) => {
  return getRequest(`images/${id}`);
};
