import BirdBuddyLogo from 'assets/images/BirdBuddy_logo_color.svg';
import BackIcon from 'assets/icons/back_icon.svg';
import ThreeDots from 'assets/icons/three_dots.svg';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';

type Props = {
  onBackButtonClick: () => void;
  showBackButton: boolean;
};

const NavBar = ({ onBackButtonClick, showBackButton = false }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <nav className="navbar">
        <div className="back-button-container">
          {!!showBackButton && (
            <a onClick={onBackButtonClick} className="d-flex no-select">
              <img src={BackIcon} />
              {!isMobile && <>Back</>}
            </a>
          )}
        </div>
        <div onClick={() => window.location.reload()} className="navbar__title">
          <img src={BirdBuddyLogo} />
        </div>
        {!isMobile ? (
          <div className="navbar__items">
            <a
              rel="noopener noreferrer"
              href="https://mybirdbuddy.com"
              className="no-select"
              target="_blank"
            >
              Shop
            </a>
            <a
              rel="noopener noreferrer"
              href="https://blog.mybirdbuddy.com"
              className="no-select"
              target="_blank"
            >
              Blog
            </a>
          </div>
        ) : (
          <div className="dropdown">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <img src={ThreeDots} />
            </button>
          </div>
        )}
      </nav>
      <div
        className={`collapse navbar-collapse ${open && 'show'}`}
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a
              rel="noopener noreferrer"
              href="https://mybirdbuddy.com"
              className="no-select"
              target="_blank"
            >
              Shop
            </a>
          </li>
          <li className="nav-item">
            <a
              rel="noopener noreferrer"
              href="https://blog.mybirdbuddy.com"
              className="no-select"
              target="_blank"
            >
              Blog
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavBar;
