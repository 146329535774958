const Colors: { [index: string]: string } = {
  DARK_BLUE_5: '#919BB4',
  DARK_BLUE_10: '#233869',
  GREEN: '#5fca9a',
  GREEN_10: '#1BB36E',
  GRAY: '#E5E5E5',
  GRAY_2: '#EAEAEA',
  GRAY_10: '#1E1E1E',
  LIGHT_BLACK: '#4A4A4A',
  BLACK: '#000000',
  WHITE: '#FFFFFF'
};

export default Colors;
