import { AxiosRequestHeaders, RawAxiosRequestHeaders } from 'axios';
import { axiosInstance } from './apiInstances';

export const getRequest = (
  url: string,
  headers: AxiosRequestHeaders | undefined = undefined,
  params: unknown = undefined
) => {
  return axiosInstance.get(url, {
    headers,
    params
  });
};

export const postRequest = (
  url: string,
  headers: RawAxiosRequestHeaders | undefined = undefined,
  data: unknown = undefined,
  params: unknown = undefined
) => {
  return axiosInstance.post(url, data, {
    params,
    headers
  });
};

export const putRequest = (
  url: string,
  headers: AxiosRequestHeaders | undefined = undefined,
  data: unknown = undefined,
  params: unknown = undefined
) => {
  return axiosInstance.put(url, data, { params, headers });
};

export const deleteRequest = (
  url: string,
  headers: AxiosRequestHeaders | undefined = undefined,
  params: unknown = undefined
) => {
  return axiosInstance.delete(url, {
    headers,
    params
  });
};
