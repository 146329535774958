import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import Button, { ButtonCustomClasses } from './Button';

interface FileWithPreview extends FileWithPath {
  preview: string;
}

type Props = {
  selectedImage?: string;
  imageUploaded: boolean;
  textSize: number;
  topText: string;
  bottomText: string;
  onChange: (acceptedFiles: File[]) => void;
  onLoad: (ev: SyntheticEvent<HTMLImageElement, Event>) => void;
};

const ImageUpload = ({
  selectedImage,
  imageUploaded,
  textSize,
  topText,
  bottomText,
  onChange,
  onLoad
}: Props) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: FileWithPreview) =>
        URL.revokeObjectURL(file.preview)
      );
  }, [files]);

  const onDrop = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acceptedFiles: any) => {
      setFiles(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file) // to preview a single image
          })
        )
      );
      onChange(acceptedFiles);
    },
    [onChange]
  );

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      accept: { 'image/*': ['.jpeg', '.png', '.jpg'] },
      maxFiles: 1,
      multiple: false
    });

  const singleImagePreview = () => {
    return (
      <div className="image-upload__preview">
        {!!topText && !imageUploaded && (
          <span className="top-text" style={{ fontSize: `${textSize}px` }}>
            {topText}
          </span>
        )}
        {selectedImage ? (
          <img src={selectedImage} onLoad={(ev) => onLoad(ev)} />
        ) : (
          files.map((file: FileWithPreview, index: number) => (
            <img
              key={index}
              src={file.preview}
              // Revoke data uri after image is loaded
              onLoad={(ev) => {
                URL.revokeObjectURL(file.preview);
                onLoad(ev);
              }}
            />
          ))
        )}
        {!!bottomText && !imageUploaded && (
          <span className="bottom-text" style={{ fontSize: `${textSize}px` }}>
            {bottomText}
          </span>
        )}
      </div>
    );
  };

  const fileRejectionItems = fileRejections.map(
    ({
      file,
      errors
    }: {
      file: FileWithPath;
      errors: { code: string; message: string }[];
    }) => {
      return (
        <li key={file.path}>
          <p>
            {file.path} - {file.size} bytes
          </p>
          <ul>
            {errors.map((e) => (
              <li key={e.code}>
                <p>{e.message}</p>
              </li>
            ))}
          </ul>
        </li>
      );
    }
  );

  const renderEmptyContent = () => {
    if (fileRejections.length > 0) return;
    return (
      <div className="image-upload__empty-content">
        <p className="upload-title">Upload image</p>
        <p className="description">
          Take a pick from <span>our awesome image library!</span>
        </p>
        <div className="divider">
          <p>or</p>
          <span />
        </div>
        <span {...getRootProps()}>
          <input {...getInputProps()} />
          <Button
            title="Browse your files"
            onClick={() => null}
            customClass={ButtonCustomClasses.BLUE}
          />
        </span>
      </div>
    );
  };
  const wrapperClasses = imageUploaded
    ? 'image-upload__wrapper image-upload__wrapper--rotate'
    : 'image-upload__wrapper';
  return (
    <div className="image-upload">
      <div className={wrapperClasses}>
        <div className="image-upload__content">
          {acceptedFiles.length > 0 || selectedImage
            ? singleImagePreview()
            : renderEmptyContent()}
          {fileRejectionItems}
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
