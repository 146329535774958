import {
  getImages,
  createNewImage,
  getSingleImage,
  getGalleryImages
} from './imageEndpoints';
import { getMemes, createNewMeme, getSingleMeme } from './memeEndpoints';

export default {
  getImages,
  createNewImage,
  getGalleryImages,
  getSingleImage,
  getMemes,
  createNewMeme,
  getSingleMeme
};
